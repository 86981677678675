<template>
	<b-card>
		<div class="list-view">
			<b-row class="p-1 m-0 top-header">
				<b-col cols="12" md="6" class="d-flex justify-content: space-around">
					<div @click="goBack" class="mr-2 mt-1 d-flex justify-content-center align-items-center">
						<feather-icon icon="ArrowLeftIcon" size="24" />
						<span class="shed_details ml-2" style="font-size: 24px; font-weight: 500"> Shed Details </span>
					</div>
				</b-col>
				<b-col cols="12" md="6" class="d-flex justify-content-end align-items-end mt-1">
					<div class="mr-5 info justify-content-center">
						<span>Shed Code : {{ shed.shed_code || "NA" }}</span>
					</div>
				</b-col>
			</b-row>
			<table class="mx-5 mt-1 mb-3 w-100">
				<!-- <tr>
          <th class="p-1">
            <b-img :src="require('@/assets/images/svg/reports/culled.svg')" class="svg-img" />
            <span class="font-weight-bold">Farm Name </span>
          </th>
          <td>{{ shed.farm.name || "NA" }}</td>
        </tr> -->
				<tr>
					<th class="p-1">
						<!-- <b-img :src="
              require('@/assets/images/svg/reports/candlingInfertile.svg')
            " class="svg-img" /> -->
						<span class="font-weight-bold">Shed Name </span>
					</th>
					<td>{{ shed.shed_name || "NA" }}</td>
				</tr>
				<tr>
					<th class="p-1">
						<!-- <b-img :src="require('@/assets/images/svg/reports/calendar.svg')" class="svg-img ml-1 mr-2" /> -->
						<span class="font-weight-bold">Production Type </span>
					</th>
					<td>{{ production_type.name || "NA" }}</td>
				</tr>
				<tr>
					<th class="p-1">
						<!-- <b-img :src="require('@/assets/images/svg/reports/calendar.svg')" class="svg-img" /> -->
						<span class="font-weight-bold">Shed Created Date</span>
					</th>
					<td>
						{{ moment(shed).format("MMMM Do YYYY") }}
					</td>
				</tr>
				<tr>
					<th class="p-1">
						<!-- <b-img :src="require('@/assets/images/svg/reports/culled.svg')" class="svg-img" /> -->
						<span class="font-weight-bold">Status </span>
					</th>
					<td>
						{{ shed.status || "NA" }}
					</td>
				</tr>
				<tr>
					<th class="p-1">
						<!-- <b-img :src="require('@/assets/images/svg/reports/weight.svg')" class="svg-img" /> -->
						<span class="font-weight-bold">Assigned Users:- </span>
					</th>
					<div v-for="user in assignedUsers" :key="user.id">
						<td v-if="user.roles[0].name === 'Admin'">
							<span class="font-weight-bold">{{ user.roles[0].name }} :- </span> {{ user.name }}
						</td>
						<td v-if="user.roles[0].name === 'Manager'">
							<span class="font-weight-bold">{{ user.roles[0].name }} :- </span> {{ user.name }}
						</td>
						<td v-if="user.roles[0].name === 'Assistant Manager'">
							<span class="font-weight-bold">{{ user.roles[0].name }} :- </span> {{ user.name }}
						</td>
						<td v-if="user.roles[0].name === 'Supervisor'">
							<span class="font-weight-bold">{{ user.roles[0].name }} :- </span> {{ user.name }}
						</td>

						<!-- <td>{{ user.name }} : ({{ user.roles[0].name }}) <br /></td> -->
					</div>
				</tr>
			</table>
		</div>
	</b-card>
</template>

<script>
import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
	components: {
		vSelect,
	},
	data() {
		return {
			shed: {},
			production_type: {},
			assignedUsers: {},
		};
	},

	created() {
		const farmId = this.$route.params.farmId;
		const shedId = this.$route.params.shedId;
		this.getShedList();
		this.getView(farmId, shedId);
	},
	methods: {
		moment() {
			return moment();
		},
		goBack() {
			this.$router.go(-1);
		},
		getShedList() {
			const farmId = this.$route.params.farmId;
			axiosIns
				.get(`web/farm/${farmId}/sheds`)
				.then((response) => {
					this.shedList = response.data.shed_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		getView(farmId, shedId) {
			axiosIns
				.get(`web/sheds/${shedId}`)
				.then((response) => {
					this.shed = response.data.shed_info;
					this.assignedUsers = response.data.shed_info.assigned_users;
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.list-view {
	background-color: #fafafa;
	box-shadow: 0px 3px 6px #38c06c33;
	height: 100%;
	padding: 0;
	margin: auto;
}

.head-text {
	font-size: 18px;
	color: #1d1d1d;
}

.info-text {
	color: rgba(41, 109, 180, 1);
	font-size: 21px;
}

.success {
	background-color: #d7f3e1;
	font-size: 11px;
	color: rgba(41, 109, 180, 1);
	display: flex;
	align-items: center;
	width: 115px;
	height: 33px;
	border-radius: 16px;
	justify-content: center;
}

.info {
	background-color: #5dadfd33;
	font-size: 11px;
	color: #5dadfd;
	display: flex;
	align-items: center;
	width: 150px;
	height: 33px;
	justify-content: center;
	border-radius: 16px;
}

.svg-img {
	margin-right: 10px;
}

table tr {
	margin: 1rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
